import _ from 'lodash';
import { hideIf, showIf } from '@parkmobile/ui';
import PropTypes from 'prop-types';
import Head from 'next/head';
import React from 'react';
import { usePageMetadata } from './page-metadata-hook';

PageMetadata.propTypes = {
  /**
   * Determines if the app name should be appended to the end of the title
   */
  decorateTitle: PropTypes.bool,
  /**
   * Sets the meta description for the page. Note: only needed when the page is indexed.
   */
  description: PropTypes.string,
  /**
   * Determines if the page should allow crawling by search indexes
   */
  indexed: PropTypes.bool,
  /**
   * Sets the page title. Note: arrays will be joined by "|" and " | {appName}"
   * is always appended to the title string.
   */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export function PageMetadata(props) {
  const { decorateTitle = true, description, indexed = false, title } = props;
  const [data] = usePageMetadata();
  const { appName } = data;

  const baseTitle = `${
    _.isArray(title) ? title.filter(Boolean).join(' | ') : title
  }`;
  const pageTitle = decorateTitle ? `${baseTitle} | ${appName}` : baseTitle;

  return (
    <Head>
      {hideIf(indexed)(<meta content='noindex' name='robots' />)}
      {showIf(baseTitle)(<title>{pageTitle}</title>)}
      {showIf(description)(<meta content={description} name='description' />)}
    </Head>
  );
}
