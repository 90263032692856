import PropTypes from 'prop-types';
import React from 'react';
import oneConfig from '@parkmobile/one-config';
import * as Sentry from '@sentry/nextjs';

import { ErrorView, PageMetadata } from '@/app/components';

export default class ErrorPage extends React.PureComponent {
  static async getInitialProps(ctx) {
    const { res, err } = ctx;
    const isSentryEnable =
      oneConfig.get('IS_EASYPARK') && Boolean(oneConfig.get('SENTRY_ENV'));

    if (isSentryEnable) {
      await Sentry.captureUnderscoreErrorException(ctx);
    }

    if (res) {
      return { statusCode: res.statusCode };
    }

    if (err) {
      return { statusCode: err.statusCode };
    }

    return { statusCode: null };
  }

  static config = {
    isAuthRequired: false,
    layout: 'fluid',
  };

  static propTypes = {
    statusCode: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf([null]),
    ]),
  };

  render() {
    const { statusCode } = this.props;
    return (
      <>
        <PageMetadata key='error-metadata' title={[`${statusCode}`, 'Error']} />
        <ErrorView key='error-view' statusCode={statusCode} />
      </>
    );
  }
}
