import dynamic from 'next/dynamic';

export { TokenRefreshManager } from './token-refresh-manager/token-refresh-manager';

export const ConfirmationView = dynamic(
  () =>
    import('./confirmation-view/confirmation-view').then(
      (module) => module.ConfirmationView
    ),
  { ssr: false }
);

export const DurationView = dynamic(
  () =>
    import('./duration-view/duration-view').then(
      (module) => module.DurationView
    ),
  { ssr: false }
);

export const LoginView = dynamic(
  () => import('./login-view/login-view').then((module) => module.LoginView),
  { ssr: false }
);

export const ParkingManagementView = dynamic(
  () =>
    import('./parking-management-view/parking-management').then(
      (module) => module.ParkingManagementView
    ),
  { ssr: false }
);

export const ParkingHistoryView = dynamic(
  () =>
    import('./parking-history-view/parking-history').then(
      (module) => module.ParkingHistoryView
    ),
  { ssr: false }
);

export const PaymentView = dynamic(
  () =>
    import('./payment-view/payment-view').then((module) => module.PaymentView),
  { ssr: false }
);

export const RedirectView = dynamic(
  () =>
    import('./redirect-view/redirect-view').then(
      (module) => module.RedirectView
    ),
  { ssr: false }
);

export const ReviewView = dynamic(
  () => import('./review-view/review-view').then((module) => module.ReviewView),
  { ssr: false }
);

export const StartView = dynamic(() =>
  import('./start-view/start-view').then((module) => module.StartView)
);

export const VehicleView = dynamic(
  () =>
    import('./vehicle-view/vehicle-view').then((module) => module.VehicleView),
  { ssr: false }
);
