import { AppConfig } from '@/lib/shared/models';
import { getAppConfig } from '@/lib/shared/store';
import { useSelector } from 'react-redux';

export function usePageMetadata() {
  const appConfig = useSelector(getAppConfig);
  const appName = AppConfig.getName(appConfig);

  return [
    {
      appName,
    },
    {},
  ];
}
